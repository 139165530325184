<template>
    <div class="search_container">
        <div class="searchHeader">
            <van-nav-bar title="筛选视频" left-arrow @click-left="comback" safe-area-inset-top fixed placeholder>
            </van-nav-bar>
        </div>
        <form action="/">
            <van-search
                    v-model="searchVal"
                    show-action
                    placeholder="输入视频名称搜索"
                    @search="onSearch"
                    @cancel="onCancel"
            >
            </van-search>
        </form>
        <div>
            <van-list
                    v-model:loading="state.loading"
                    :finished="state.finished"
                    finished-text="没有更多了"
                    @load="throttleOnLoadMore"
            >
                <video-type :videoList="state.videoList"  :myVideoListTag="state.myVideoListTag" @delete_my_video="deleteMyVideo(index)"></video-type>
            </van-list>
        </div>
        <div class="footer-place-holder"></div>
    </div>
</template>

<script>
    import { reactive, ref, onMounted } from 'vue'
    import { useRouter,useRoute } from 'vue-router'
    import {throttle} from '../../utils/commonUtil'
    import {getVideoList, getMyVideoList} from '../../api/video'
    import videoType from "../../views/video/type/type.vue"

    export default {
        components:{
            videoType
        },
        setup(props){
            const state = reactive({
                videoList:[],
                myVideoListTag: false,
                size: 5,
                currentPage: 1,
                refreshing: false,
                loading: false,
                finished: false,
            })
            const searchVal = ref('')
            const searchParams = reactive({size:state.size})

            const router = useRouter()
            const route = useRoute()
            state.myVideoListTag = route.query.myVideoListTag? true:false

            //绑定回退事件
            const comback = ()=>{
                router.back()
            }

            //曲线解决初始化的时候van-list也会触发onLoad的问题
            let initGetVideoListTag = true
            //绑定搜索事件
            const onSearch = async (val)=>{
                searchParams.name = val
                searchParams.current = state.currentPage = 1
                state.finished = false
                let result = state.myVideoListTag? await getMyVideoList(searchParams) :await getVideoList(searchParams)
                state.videoList = result.records
                //曲线解决初始化的时候van-list也会触发onLoad的问题
                initGetVideoListTag = false

            }
            // const onSearch = (val) => {
            //     searchParams.name = val
            //     getVideoList({name: val}).then((data)=>{
            //         console.log(data)
            //         state.videoList = data.records
            //     })
            // }

            const onCancel = ()=>{
                searchVal.value = ''
                console.log("取消");
            }

            onMounted(()=>{
                // initGetVideoListTag = false
            })
            //加载更多
            const onLoadMore = async()=>{
                //曲线解决初始化的时候van-list也会触发onLoad的问题
                if (initGetVideoListTag) {
                    state.loading = false
                    return false;
                }
                console.log("===state.loading===",state.loading)
                // console.log("加载更多");
                state.currentPage += 1
                let data = state.myVideoListTag? await getMyVideoList({name:searchVal.value,current:state.currentPage,size:state.size}): await getVideoList({name:searchVal.value,current:state.currentPage,size:state.size})
                if(data){
                    console.log("加载更多---------",data);
                }
                if (data.records.length > 0) {
                    state.videoList = state.videoList.concat(data.records)
                    state.loading = false
                    console.log("===state.loading===",state.loading)
                } else {
                    state.loading = false
                    state.finished = true
                    console.log("===state.loading===",state.loading)
                }
            }

            const onLoadMoreOther = () => {
                state.loading = false
                console.log("===state.loading===",state.loading)
            }

            //节流加载更多
            const throttleOnLoadMore = throttle(onLoadMore, 1000, onLoadMoreOther)

            //删除我的视频
            const deleteMyVideo = (index)=> {
                state.videoList.splice(index,1)
            }


            return {
                state,
                props,
                searchVal,
                onSearch,
                comback,
                onCancel,
                throttleOnLoadMore,
                deleteMyVideo
            }
        }
    }
</script>

<style lang="scss">
    .search_container{
        .searchHeader{
            .van-nav-bar__arrow{
                color: #333333;
            }
            .van-nav-bar__title{
                color: #333333;
                font-weight: 600;
            }

        }
        .van-search__action{
            color: #FF6E01;
        }
        .Is-comto-material{
            font-size: 14px;
            .comto-material{
                font-weight: 600;
                padding: 18px;
                text-align: left;
            }
            .select-comin{
                .selectBox{
                    float: left;
                    color: #999999;
                    width: 52px;
                    height: 28px;
                    border: 1px solid #999999;
                    line-height: 28px;
                    margin-left: 18px;
                    border-radius: 4px;
                    box-sizing: border-box;
                }
                .bgColor{
                    color: #fff;
                    background-color: #289FFF;
                    border: none;
                }

            }

        }
        .footer-place-holder {
            height: 10px;
        }

    }
</style>